// eslint-disable-next-line no-unused-vars
import React from 'react';

const DefaultFallback = () => (
	<div className="d-flex text-center flex-column align-items-center my-5 px-3">
		<h3>Oops...something went wrong here.</h3>
		<p>
			Our devs are working on that. In the meantime, try refreshing the page.
		</p>
		<button
			type="button"
			className="btn btn-primary mt-3"
			onClick={() => window.location.reload()}
		>
			Reload
		</button>
	</div>
);

class ErrorBoundary extends React.Component {
	state = {
		hasError: false,
	};

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		const { childName } = this.props;
		this.setState({ hasError: true });
		const { Sentry } = window;
		if (Sentry) {
			Sentry.configureScope((scope) => {
				scope.setTag('children', childName);
				scope.setContext('componentStack', errorInfo);
			});
			Sentry.captureException(error);
		}
	}

	render() {
		const { fallback, children } = this.props;
		const { hasError } = this.state;
		const Fallback = fallback || DefaultFallback;
		if (hasError) {
			return <Fallback />;
		}
		return children;
	}
}

export default ErrorBoundary;
