import React from 'react';

const defaultStyle = {
	fill: 'currentColor',
	width: '1em',
	height: '1em',
	fontSize: '24px',
	transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
};

const CloseIcon = ({
	onClick, style, className,
}) => (
	<svg
		onClick={onClick}
		style={{ ...defaultStyle, ...style }}
		className={className}
		focusable="false"
		viewBox="0 0 24 24"
		aria-hidden="true"
		role="presentation"
	>
		<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
		<path fill="none" d="M0 0h24v24H0z" />
	</svg>
);

export default CloseIcon;
