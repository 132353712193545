import React, { useState } from 'react';
import CloseIcon from './subcomponents/CloseIcon';

const Banner = ({ setShouldResetHeaderHeight }) => {
	const [shouldShowSelf, setShouldShowSelf] = useState(true);
	const hideSelf = () => {
		setShouldShowSelf(false);
		setShouldResetHeaderHeight(true);
	};
	return shouldShowSelf ? (
		<div className="w-100 py-3 bg-gradient-mobelux">
			<div className="text-center">
				Looking for more information on Buddy's Insurance Gateway? Click
				{' '}
				<a
					className="mobelux-link"
					href="https://buddy.insure"
				>
					here
				</a>
				{' '}
				to read more.
				<CloseIcon className="float-right mr-3" onClick={hideSelf} />
			</div>
		</div>
	) : null;
};

export default Banner;
