// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useMemo } from 'react';
import loadable from '@loadable/component';
import Header from './Header';
import SEO from './SEO';
import IF from './IF';
import ErrorBoundary from './ErrorBoundary';
import '../scss/buddy.scss';

const Footer = loadable(() => import('./Footer'));
const Modal = loadable(() => import('./Modal'));

export const ModalContext = React.createContext([{}, () => {}]);

export const ModalProvider = ({ children }) => {
	const [modal, setModal] = useState({
		modalOpen: false,
		children: null,
		// These two are required. See Modal for full list of options.
	});
	const { modalOpen, ...modalOptions } = modal;

	const openModal = (options) => setModal((current) => ({ ...current, ...options }));

	const closeModal = (removeChild = false) => (removeChild
		? setModal({ modalOpen: false, children: null })
		: setModal((current) => ({ ...current, modalOpen: false })));
	const contextValues = useMemo(() => ({ openModal, closeModal }), []);

	return (
		<ModalContext.Provider value={contextValues}>
			{modalOpen && <Modal {...modalOptions} closeModal={closeModal} />}
			{children}
		</ModalContext.Provider>
	);
};

// Chrome specific bug fix for hash links not working on initial page load
if (typeof window !== 'undefined') {
	const isChrome =		/Chrome/.test(window.navigator.userAgent)
		&& /Google Inc/.test(window.navigator.vendor);
	if (window.location.hash && isChrome) {
		setTimeout(() => {
			const { hash } = window.location;
			window.location.hash = '';
			window.location.hash = hash;
		}, 300);
	}
}

function Layout({ children, hideHeader, ...props }) {
	const [headerHeight, setHeaderHeight] = useState(72);
	const [shouldResetHeaderHeight, setShouldResetHeaderHeight] = useState(false);
	const setHeight = () => {
		try {
			if (!hideHeader) {
				setHeaderHeight(document.getElementById('buddy-header').offsetHeight);
			}
		} catch (error) {
			if (window.Sentry) {
				window.Sentry.captureMessage(`set headerHeight failed: ${error}`);
			}
		}
	};
	useEffect(() => {
		setHeight();
	}, [shouldResetHeaderHeight]);
	useEffect(() => {
		window.addEventListener('resize', setHeight);
		return () => window.removeEventListener('resize', setHeight);
	});

	return (
		<ErrorBoundary childName="topLevel">
			<ModalProvider>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100vh',
				}}
				>
					<SEO {...props} />
					<IF condition={!hideHeader}>
						<Header {...props} setShouldResetHeaderHeight={setShouldResetHeaderHeight} />
					</IF>
					<div
						className="w-100"
						style={{
							marginTop: !hideHeader ? headerHeight : 0,
							flex: '1 0 auto',
						}}
					>
						<ErrorBoundary childName="GatsbyPage">{children}</ErrorBoundary>
					</div>
					<Footer />
				</div>
			</ModalProvider>
		</ErrorBoundary>
	);
}

export default Layout;
