// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { isIE } from 'react-device-detect';
import IF from './IF';
import Banner from './Banner';

// stupid gatsby build not allowing globals.
let pathname;
if (typeof window !== 'undefined') {
	pathname = window.location.pathname;
}

function Header({ setShouldResetHeaderHeight }) {
	return (
		<>
			<div className="loader" />
			<div className="fixed-top" id="buddy-header">
				<IF condition={pathname === '/'}>
					<Banner setShouldResetHeaderHeight={setShouldResetHeaderHeight} />
				</IF>
				{isIE && (
					<div
						className="d-flex justify-content-center p-3"
						style={{ background: '#fee69e' }}
					>
						Internet Explorer is not fully supported on this site. For the Best
						viewing experience, please use an updated version of
						<a
							href="https://www.google.com/intl/en/chrome/"
							target="_blank"
							rel="noopener noreferrer"
						>
							&nbsp; Chrome
						</a>
						,
						<a
							href="https://www.microsoft.com/en-us/edge"
							target="_blank"
							rel="noopener noreferrer"
						>
							&nbsp; Edge
						</a>
						, or
						<a
							href="https://www.mozilla.org/en-US/firefox/new/"
							target="_blank"
							rel="noopener noreferrer"
						>
							&nbsp; Firefox
						</a>
						.
					</div>
				)}
				<nav
					className="navbar navbar-expand-lg navbar-dark bg-primary flex-nowrap flex-lg-wrap"
					id="main-nav"
				>
					<div className="d-flex w-100 navbar-brand-icon justify-content-center">
						<Link to="/">
							<img
								className="buddy-icon w-100"
								src="/buddy_wordmark_white.svg"
								alt="buddy logo"
							/>
						</Link>
					</div>
				</nav>
			</div>
		</>
	);
}

export default Header;
