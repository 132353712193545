/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable space-before-function-paren */
/* eslint-disable prefer-rest-params */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable quotes */
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const GA_CODE = process.env.GATSBY_GA_CODE;
const SENTRY_DSN = process.env.GATSBY_SENTRY_DSN;

const addSlash = (str) => {
	const ending = str[str.length - 1];
	if (ending === '/') {
		return str;
	}
	return `${str}/`;
};

const fbAppID = process.env.GATSBY_FB_APP_ID;

let pagePath = '/';

if (typeof window !== 'undefined') {
	pagePath = window.location.pathname;
}

function SEO({
	description,
	image: metaImage,
	title = 'Insurance for Adventures | Buddy',
	type,
	keywords,
	path = pagePath,
}) {
	const data = useStaticQuery(graphql`
				{
					site {
						siteMetadata {
							author
							description
							siteUrl
							keywords
							version
							environment
						}
					}
					logo: file(relativePath: { regex: "/fav_icon_buddy/" }) {
						childImageSharp {
							original {
								src
							}
						}
					}
					defaultImage: file(relativePath: { regex: "/buddy-banner.jpg/" }) {
						childImageSharp {
							resize(width: 1200) {
								src
								height
								width
							}
						}
					}
				}
			`);
	const { siteUrl, version, environment } = data.site.siteMetadata;
	const metaDescription =	description || data.site.siteMetadata.description;
	const image = metaImage || data.defaultImage.childImageSharp.resize;
	const imageURL = `${siteUrl}${image.src}`;
	const canonicalUrl = path
		? `${data.site.siteMetadata.siteUrl}${path}`
		: data.site.siteMetadata.siteUrl;
	const ogType = type || 'website';

	const browserSupportsAllFeatures = () => {
		if (typeof window !== 'undefined') {
			return window.Promise && window.Intl && window.Symbol;
		}
		return null;
	};

	function loadPolyfill(src) {
		if (typeof document !== 'undefined') {
			var js = document.createElement('script');
			js.src = 'https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=4.8.0';
			js.onerror = function () {
			// eslint-disable-next-line no-console
				console.log(new Error(`Failed to load script ${src}`));
			};
			document.head.appendChild(js);
		}
	}

	// GTM is now our base analytics
	const loadGTM = (w, d, s, l, i) => {
		w[l] = w[l] || [];
		w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
		const f = d.getElementsByTagName(s)[0];
		const j = d.createElement(s);
		const dl = l !== 'dataLayer' ? `&l=${l}` : '';
		j.defer = true;
		j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
		f.parentNode.insertBefore(j, f);

		const s2 = d.createElement('script');
		s2.text = `
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				window.dataLayer.push(arguments);
			}
		`;
		f.parentNode.insertBefore(s2, f);
		const { gtag } = w;
		gtag('config', i);
		gtag('js', new Date());
	};

	const loadSentry = () => {
		const asyncSentry = document.createElement('script');
		asyncSentry.src = `https://js.sentry-cdn.com/${SENTRY_DSN}.min.js`;
		asyncSentry.crossOrigin = "anonymous";
		asyncSentry['data-lazy'] = "no";
		asyncSentry.id = "sentry-async-script";
		// eslint-disable-next-line no-undef
		asyncSentry.onload = () => Sentry.onLoad(() => {
			// eslint-disable-next-line no-undef
			Sentry.init({
				environment: process.env.GATSBY_ENV,
				debug: process.env.GATSBY_ENV === 'dev',
				release: `my.buddy.insure@${version}`,
			});
		});
		asyncSentry.async = true;
		const head = document.querySelector("head");
		const scripts = head.getElementsByTagName('script');
		// this puts Sentry as the very first script.
		head.insertBefore(asyncSentry, scripts[0]);
	};

	useEffect(() => {
		// load sentry and GA right away, let's wait for the other stuff.
		loadSentry();
		loadGTM(window, document, 'script', 'dataLayer', GA_CODE);
	}, []);

	if (!browserSupportsAllFeatures()) {
		loadPolyfill();
	}

	// make sure buddy is at the end if it's not present
	const finalTitle = title.includes('Buddy') ? title : `${title} | Buddy`;

	const metaTags = [
		{
			name: "viewport",
			content: "width=device-width, initial-scale=1",
		},
		{
			name: 'description',
			content: metaDescription,
		},
		{
			name: 'keywords',
			content: keywords
				? data.site.siteMetadata.keywords + keywords
				: data.site.siteMetadata.keywords,
		},
		{
			property: 'og:title',
			content: finalTitle,
		},
		{
			property: 'og:description',
			content: metaDescription,
		},
		{
			name: 'twitter:creator',
			content: data.site.siteMetadata.author,
		},
		{
			name: 'twitter:title',
			content: finalTitle,
		},
		{
			name: 'twitter:description',
			content: metaDescription,
		},
		{
			property: 'og:url',
			content: canonicalUrl,
		},
		{
			property: 'og:type',
			content: ogType,
		},
		{
			property: 'og:image',
			content: imageURL,
		},
		{
			property: 'og:image:width',
			content: image.width,
		},
		{
			property: 'og:image:height',
			content: image.height,
		},
		{
			name: 'twitter:card',
			content: 'summary_large_image',
		},
		{
			property: 'fb:app_id',
			content: fbAppID,
		},
	];
	if (environment !== 'production') {
		metaTags.unshift({
			name: 'robots',
			content: 'noindex',
		});
	}

	return (
		<>
			<Helmet
				htmlAttributes={{
					lang: 'en',
				}}
				title={finalTitle}
				link={[
					{
						rel: 'canonical',
						href: addSlash(canonicalUrl),
					},
					{
						rel: 'preconnect',
						href: 'https://fonts.googleapis.com',
						crossOrigin: true,
					},
					{
						rel: 'preconnect',
						href: 'https://www.google-analytics.com',
						crossOrigin: true,
					},
				]}
				meta={metaTags}
			>
				<script type="application/ld+json">
					{`{
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "${data.site.siteMetadata.siteUrl}",
              "logo": "${data.site.siteMetadata.siteUrl}${data.logo.childImageSharp.original.src}"
            }`}
				</script>
			</Helmet>
			<noscript>
				<iframe
					title="gtm"
					src={`https://www.googletagmanager.com/ns.html?id=${GA_CODE}`}
					height="0"
					width="0"
					style={{ display: 'none', visibility: 'hidden' }}
				/>
			</noscript>
		</>
	);
}

export default SEO;
